<template>
    <v-container fluid class="success-wrapper fill-height">
        <v-row justify="center" align="center">
            <v-col cols="12" sm="10" md="8" lg="6" class="px-5 my-5">
                <v-card class="mx-auto" max-width="480">
                    <v-img class="white--text align-end" height="320px" src="../assets/banner/success.jpg">
                        <v-card-title class="success-title">Sua conta foi criada</v-card-title>
                    </v-img>

                    <v-card-subtitle class="pb-0">Configure sua agenda</v-card-subtitle>
                    <v-card-text class="text--primary">
                        <div class="subtitle-2 my-2">Agora você pode personalizar o que quiser</div>
                        <div class="body-2 my-4">Envie fotos, cadastre serviços e produtos, veja seus clientes, horários e muito mais</div>
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text href="https://configuracoes.appagendamento.com/#/">acessar minha agenda</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'account-created',
    mounted() { fbq('track', 'Purchase', { currency: 'BRL', value: 29.90 }) }
}
</script>

<style lang="scss">
.success-title {
    background-color: rgba(0, 0, 0, .65);
}

.success-wrapper {
    background-image: url(../assets/slides/3.jpg);
    background-position: center;
    background-size: cover;
}
</style>